@font-face {
    font-family: 'Agrandir-Title';
    src: url('../public/assets/fonts/Agrandir-GrandLight.otf') format('woff2'),
         url('../public/assets/fonts/Agrandir-GrandLight.otf') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Agrandir-Paragraph';
    src: url('../public/assets/fonts/Agrandir-Regular.otf') format('woff2'),
         url('../public/assets/fonts/Agrandir-Regular.otf') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Agrandir-WideBlackItalic';
    src: url('../public/assets/fonts/Agrandir-WideBlackItalic.otf') format('woff2'),
         url('../public/assets/fonts/Agrandir-WideBlackItalic.otf') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Agrandir';
    src: url('../public/assets/fonts/Agrandir-Narrow.otf') format('woff2'),
         url('../public/assets/fonts/Agrandir-Narrow.otf') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Agrandir-Bold';
    src: url('../public/assets/fonts/Agrandir-TextBold.otf') format('woff2'),
         url('../public/assets/fonts/Agrandir-TextBold.otf') format('woff');
    font-weight: 700;
    font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

:root {
  --color-primary: #AFFFF7;
  --color-secondary: #00DBC5;
  --color-background: #2F756E;
  --color-background-transparent: rgba(47, 117, 110, 0.7);
  --color-white: #fff;

  --container-width-lg: 86%;
  --container-width-md: 90%;
  --container-width-sm: 94%;
  --form-width: 50%;

  --transition: all 400ms ease;
}



body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: "Agrandir", sans-serif;
  line-height: 1.7;
  color: var(--color-white);
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

section h2 {
  text-align: center;
  margin-bottom: 4rem;
}

h1, h2, h3, h4, h5 {
  line-height: 1.2;
}

h1 {
  font-size: 2.4rem;
  font-family: "Agrandir-Title", sans-serif;
}

h2 {
  font-size: 2rem;
  font-family: "Agrandir-WideBlackItalic", sans-serif;
  font-weight: 300;
  letter-spacing: 4px;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.3rem;
}

h5 {
  font-family: "Rocknroll One", sans-serif;
  font-size: 0.9rem;
  word-spacing: 0.2rem;
  line-height: 1.5;
}

a {
  color: var(--color-white);
}

p {
  font-family: "Agrandir-Paragraph", sans-serif;
  letter-spacing: 2px;
}

img {
  width: 100%;
  display: block;
  object-fit: cover;
  transition: var(--transition);
}

img:hover {
  transform: scale(1.02);
}


.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Other container styles */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

/* Other CSS remains the same... */



.btn {
  display: inline-block;
  background: var(--color-secondary);
  color: var(--color-background);
  padding: 0.33rem 1.4rem;
  border: 3px solid transparent;
  border-radius: 112px;
  font-weight: 400;
  transition: var(--transition);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adjust shadow to match the design */
  font-family: "Agrandir-Bold", sans-serif;
}

.btn:hover {
  background: var(--color-background);
  color: var(--color-white);
  border-color: var(--color-white);
}

.btn-primary {
  background: var(--color-secondary);
  color: var(--color-background);
}

.btn-secondary {
  background: var(--color-primary);
  color: var(--color-background);
}

.btn-background {
  background: var(--color-background);
  color: var(--color-white);
}

/*====================  Hero Template =============================*/

header {
  display: flex; /* This makes header a flex container */
  justify-content: center; /* This centers its children horizontally */
  align-items: center; /* This centers its children vertically */
  flex-direction: column; /* This stacks children vertically */
  padding-top: 12rem;
  border-bottom: 10px solid var(--color-white); /* Add a white stroke below the header */
}

.header__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 5rem;
  height: 100%;
  position: relative;
}


.header__left p {
  margin: 1rem 0 2.4rem;
  color: var(--color-primary);
}

.header__left h1 {
  margin-top: -150px;
  margin-bottom: 70px;
  letter-spacing: 10px;
  font-weight: 100;
  font-size: 60px;
  color: var(--color-primary);
}

#spline-container {
  width: 100%; /* Adjust to 70% of the container's width */
  overflow: hidden; /* Hide the overflow part */
  height: 400px; /* Adjust height if needed */
  min-height: 100px;
}

spline-viewer {
  margin: 0 auto;
}

.header__right {
  flex-basis: 80%; /* Set the flex item's base size */
  overflow: hidden; /* Hide the overflow */
  position: relative;
}

#spline-container::after {
  content: 'Click and Hover'; /* The text to display */
  display: flex; /* Makes the pseudo-element a flex container */
  justify-content: center; /* Centers content horizontally within the container */
  align-items: center; /* Centers content vertically within the container */
  position: absolute;
  bottom: 18px; /* Adjusted to position the element at the bottom */
  right: 10px; /* Positions the element to the right */
  width: 50%; /* Width of the element */
  height: 45px; /* Height of the element, sufficient to cover the watermark */
  border-radius: 22px; /* Gives rounded corners to the element */
  background: var(--color-background); /* Sets the background color */
  color: var(--color-white); /* Sets the text color for visibility */
  z-index: 2; /* Ensures it sits on top of other content */
  transition: var(--transition);
  box-shadow: -4px 3px 4px rgba(0, 0, 0, 0.192); /* Adjust shadow to match the design */
}

#spline-container:hover::after {
  box-shadow: -4px 5px 2px rgba(0, 0, 0, 0.192); /* Adjust shadow to match the design */
  transform: scale(1.1); /* Scale the element */
}

.header-home {
  background-image: linear-gradient(rgba(47, 117, 110, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%), url('../public/assets/Hero Image.png');
  min-height: 100vh;
  background-position: center;
  background-size: cover; /* Ensure the image covers the element without stretching */
  background-repeat: no-repeat;
  background-attachment: fixed; /* Ensures the image does not scroll */
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.header-order {
  background-image: linear-gradient(rgba(47, 117, 110, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%), url('../public/assets/Hero Image.png');
  min-height: 100vh;
  background-position: center;
  background-size: cover; /* Ensure the image covers the element without stretching */
  background-repeat: no-repeat;
  background-attachment: fixed; /* Ensures the image does not scroll */
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}


.home-background {
  width: 100%;
  background: url('./../public/assets/Home Background.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: scroll;
}

.order-background {
  width: 100%;
  background: url('./../public/assets/Order Background.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: scroll;
}



.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
  margin-top: 50px;
}

.about-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.about-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
  color: var(--color-background);
}

.about-left h1 {
  letter-spacing: 7px;
  font-weight: 30;
  font-size: 30px;
  opacity: 70%;
}

.about-image {
  max-width: 50%;
  height: auto;
  margin-bottom: 30px;
}

.about-right {
  flex: 1;
  padding: 20px;
  color: var(--color-background);
  text-align: right;
}

.about-right h2 {
  text-align: right;
}

.about-right p {
  margin-bottom: 70px;
}


 /* ================  Card Section   ============================== */


 .card-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px; /* Adjust the space between the cards */
  flex-wrap: wrap; /* Wrap the items for responsiveness */
  margin-top: 150px;
}

.card {
  background-color: var(--color-background);
  color: var(--color-primary);
  border-radius: 50px;
  padding: 50px;
  box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.4); /* Optional shadow for depth */
  text-align: center;
  flex-basis: calc(33.333% - 20px); /* Adjust the width of the cards */
  max-width: calc(33.333% - 50px); /* Maximum width of the cards */
  box-sizing: border-box; /* Include padding and border in the element's width and height */
  transition: var(--transition);
  font-size: 14px;
}

.card:hover {
  box-shadow: 6px 8px 2px rgba(0, 0, 0, 0.4); /* Optional shadow for depth */
  transform: scale(1.05);
}

.card h1 {
  margin-top: 0;
  font-size: 20px;
  letter-spacing: 3px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px; /* Adjust the space between icon and text */
}

.feature-icon {
  margin-top: 30px;
  width: 70px; /* Maintain the aspect ratio of the image */
  height: 70px; /* Maintain the aspect ratio of the image */
  transition: var(--transition);
  margin-bottom: -20px;
  color: var(--color-white);
}

.feature-icon:hover {
  transform: scale(1.05);
  filter: invert(10%) sepia(50%) saturate(10000%) hue-rotate(100deg)
}

.title-mino {
  text-align: center;
  color: var(--color-background);
  margin-bottom: -50px;
  margin-top: 100px;
}


/*====================  Types  =============================*/



.types-section {
  padding: 50px 0;
}

.types-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 50px;
  color: var(--color-background);
  margin-top: 150px;
}

.types-slide {
  display: flex;
  align-items: center;
  padding: 40px;
  background: var(--color-background-transparent);
  border-radius: 40px;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.3);
  margin: 10px;
  margin-bottom: 30px;
  transition: var(--transition);
}

.types-slide:hover {
  transform: scale(1.02);
}

.types-slide img {
  border-radius: 22px;
  filter:drop-shadow(3px 4px 3px rgba(0, 0, 0, 0.3));
  max-width: 25%;
  margin-right: 20px;
  height: 250px;
  width: 250px;
}

.types-slide h4 {
  margin-bottom: 10px;
  color: var(--color-primary);
  margin-bottom: 40px;
  padding-left: 30px;
}

.types-slide p {
  margin-bottom: 20px;
  font-size: 13px;
  padding-left: 30px;
}

.order-btn {
  display: block;
  margin: 30px auto;
  width: 15%;
  align-self: center;
  text-align: center;
}

.product-swiper-pagination {
  bottom: 0px;
  left: 0;
  width: 100%;
  text-align: center;
  position: absolute;
}

.product-swiper-pagination-bullet::after {
  background-color: var(--color-background-transparent); /* Adjust color as needed */
  width: 12px;
  height: 12px;
  margin: 3px;
}

.product-swiper-pagination-bullet-active::after {
  background-color: var(--color-background); /* Adjust color as needed */
}


/* ====================== Reviews =============================== */

.reviews-section {
  padding: 50px 0;
}

.reviews-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 50px;
  color: var(--color-background);
}

.review-slide {
  display: flex;
  align-items: center;
  padding: 40px;
  background: var(--color-background-transparent);
  border-radius: 40px;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.3);
  margin: 10px;
  margin-bottom: 30px;
  transition: var(--transition);

}

.review-slide:hover {
  transform: scale(1.02);
}

.review-slide img {
  border-radius: 22px;
  filter: drop-shadow(3px 4px 3px rgba(0, 0, 0, 0.3));
  max-width: 25%;
  margin-right: 150px;
  height: 300px;
  width: 300px;
}

.review-content {
  display: flex;
  flex-direction: column;
}

.review-content h4 {
  margin-bottom: 20px;
  color: var(--color-primary);
  margin-left: 100px;
  margin-bottom: 40px;
}

.stars {
  display: flex;
  flex-direction: column;
}

.star {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
  gap:10px;
  color: var(--color-primary);
}

.star .slider {
  background-color: var(--color-primary);
  height: 10px;
  border-radius: 5px;
  margin-left: 10px;
  flex: 1;
}


/* ====================== Logo Showcase =============================== */


.logo-showcase {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  padding-top: 20rem;
  padding-bottom: 3rem;
  filter: drop-shadow(7px 8px 1px rgba(26, 26, 26, 0.374));
  transition: var(--transition);
}

.logo-showcase:hover {
  filter: drop-shadow(10px 10px 1px rgba(26, 26, 26, 0.374));
}

.logo-image {
  height: auto; /* Maintains the aspect ratio */
  width: 200px;
}

.logo-showcase-brand {
  margin-bottom: 20px;
  letter-spacing: 10px;
  font-weight: 100;
  font-size: 60px;
  color: var(--color-background);
  text-align: center;
}

.logo-showcase-slogan {
  letter-spacing: 7px;
  font-weight: 30;
  font-size: 25px;
  opacity: 70%;
  text-align: center;
  color: var(--color-background);
}

/* ====================== Footer =============================== */


.footer {
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  color: var(--color-white);
  justify-content: space-between;
}

.footer-img img {
  transition: var(--transition);
}

.footer-img img:hover {
  transform: scale(1.1)
}

.location-icon {
  transition: var(--transition);
  margin-right: 20px; /* Adjust as needed for spacing between columns */
  height: 25px;
}

.location-icon:hover {
  transform: scale(1.1)
}

.footer-copy {
  text-align: center; /* Centers the text horizontally */
  opacity: 0.78; /* Sets the opacity to 78% */
  width: 100%; /* Ensures it spans the full width of its container */
  order: 3; /* Puts it at the end of the footer, even if other elements have flex order */
  font-size: 15px;
  padding-bottom: 5px;
  padding-top: 50px;
}

.footer-copy-synaps {
  text-align: center; /* Centers the text horizontally */
  opacity: 0.78; /* Sets the opacity to 78% */
  width: 100%; /* Ensures it spans the full width of its container */
  order: 3; /* Puts it at the end of the footer, even if other elements have flex order */
  font-size: 15px;
  padding-bottom: 30px;
}



.footer-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 200px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-column:not(:last-child) {
  margin-right: 20px; /* Adjust as needed for spacing between columns */
}

.footer-logo, .footer-location, .footer-contact, .footer-socials {
  margin-bottom: 10px; /* Adjust as needed */
}

.footer-slogan, .footer-copy {
 
  margin-top: auto; /* Pushes the slogan and copyright to the bottom */
}

.footer-slogan {
  margin-top: 20px;
}


.footer-link {
  display: block;
  margin-bottom: 5px; /* Adjust as needed for spacing between links */
  text-decoration: underline;
  transition: var(--transition)
}


.footer-link:hover {
  color: var(--color-primary);
}

.blog-log-in-btn {
  transition: var(--transition);
}


.blog-log-in-btn:hover {
  color: var(--color-primary);
}


.socials-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.social-row {
  display: flex;
  align-items: center; /* This will vertically center align the icon with the text */
  margin-bottom: 10px; /* Spacing between rows */
}

.social-row img {
  margin-right: 8px; /* Space between the icon and text, adjust as necessary */
}


.social-row img {
  width: 24px; /* Adjust width as needed */
  height: 24px; /* Adjust height as needed */
  margin-right: 15px; /* Space between the icon and text */
  /* If you want to keep the images' aspect ratio, you can set only one dimension (width or height) and set the other to 'auto'. */

}


.footer-copy-designed-by-synaps {
  font-size: 20px;
  font-family: "Agrandir-Paragraph";
  font-weight: 600;
  text-decoration:underline;
}


/* ====================== Order Form =============================== */


.order-section {
  padding: 50px 0;
  width: 80%;
}

.order-section p {
  color: var(--color-background);
  margin-bottom: 50px;
  text-align: center;
}

.transport-p {
  margin-top: -30px;
}

.order-section h3 {
  font-size: 2em;
  color: var(--color-background);
  margin-bottom: 20px;
  text-align: center;
  margin-top: 50px;
}

.order-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  margin-top: 70px;
  color: var(--color-background);
}

.order-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  gap: 20px;
}

.order-form input {
  padding: 10px;
  border-radius: 22px;
  border: 2px solid var(--color-secondary);
  width: calc(50% - 10px);
}

.order-form h3 {
  font-size: 1.5em;
  color: var(--color-background);
  margin-bottom: 10px;
}

.order-form p {
  font-size: 0.9em;
  color: var(--color-background);
}

.product-selection {
  display: grid; /* Ensures the image is treated as a block element */
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  gap: 10px; /* Decreased gap between products */
  color: var(--color-primary);
  margin: 0 auto;
  margin-bottom: 10px;
}

.product {
  cursor: pointer;
  padding: 15px;
  border: 2px solid transparent;
  border-radius: 10px;
  transition: border-color 0.3s, transform 0.3s;
  text-align: center;
  background-color: var(--color-background-transparent);
  box-shadow: 4px 5px 7px rgba(0, 0, 0, 0.3); /* Optional shadow for depth */
  width: 210px; /* Set a fixed width for all product containers */
  margin: 0 10px 20px; /* Center the product containers */
}


.product img {
  display: block; /* Ensures the image is treated as a block element */
  margin: 0 auto; /* Centers the image horizontally */
  height: 150px;
  width: 150px;
  margin-bottom: 10px;
  text-align: center;
  filter: drop-shadow(7px 8px 1px rgba(26, 26, 26, 0.374));
  
}

.product:hover {
  border-color: var(--color-primary);
  transform: scale(1.13);
  box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.4); /* Optional shadow for depth */
}

.product.selected {
  border-color: var(--color-secondary);
  transform: scale(1.1);
}



.total-price {
  text-align: center;
  font-size: 1.5em;
  color: var(--color-primary);
  padding: 10px;
  border-radius: 40px;
  background: var(--color-background-transparent);
  width: 30%;
  margin: 0 auto; /* Centers the element horizontally */
  text-align: left;
  margin-top: 50px;
}

.total-price h4 {
  margin-left: 30px;
  letter-spacing: 2px;
}


.btn-submit-form {
  align-self: center;
  margin-top: 20px;
}










/* ====================== Responsive Tablets =============================== */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  .btn {
    padding: 0.4rem 2rem; /* Increases button size */
  }

  .container, .main-container {
    padding: 0 20px; /* Adds padding on the sides */
  }
  #spline-container::after {
    width: 100%; /* Width of the element */
  }
  .logo-showcase {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    min-height: 50vh; /* Optional: Change as per your design needs */
    filter: drop-shadow(7px 8px 1px rgba(26, 26, 26, 0.374));
    transition: var(--transition);
    width: 100%;
    height: 100%;
  }

  .logo-showcase img {
    height: 150px; /* Maintains the aspect ratio */
    width: 150px;
    margin-bottom: -100px;
  }

  .review-slide {
    flex-direction: column;
    align-items: center;
  }

  .review-slide img {
    max-width: 50%;
    margin-bottom: 20px;
    margin-right: 0px;
  }

  .product-selection {
    display: grid; /* Ensures the image is treated as a block element */
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
  }

  
}

/* ====================== Responsive Phones =============================== */

@media screen and (max-width: 768px) {
  .container {
    width: var(--container-width-sm); /* Uses smaller container width */
  }

  .btn {
    padding: 0.4rem 1.5rem; /* Reduces button size for space */
  }

  /* Increase base text size for readability */
  body {
    font-size: 1rem; /* Increases text size */
  }

  /* Make images and sections adapt to smaller screens */
  img, .section {
    width: 100%;
    height: auto;
  }

  .hero-div h1 {
    font-size: 2.5rem; /* Adjusts hero text size */
  }

  .header__container, .about-section, .services-section, .portfolio-section {
    padding: 0 15px; /* Reduces padding */
    justify-content: center; /* Center the image container */
  }

  spline-viewer {
    height: 500px;
    margin-left: 50px;
  }

  #spline-container::after {
    width: 70%; /* Adjust to 70% of the container's width */
  }

  .header__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px; /* This adds space between your flex items */
  }

  .header__left, .header__right {
    width: 100%; /* This ensures each child takes full width */
    margin-bottom: 20px; /* This adds space between your flex items */
    text-align: center;
  }

  .header__left h1 {
    margin-top: 0px;
    margin-bottom: 70px;
    letter-spacing: 10px;
    font-weight: 100;
    font-size: 40px;
    color: var(--color-primary);
  }

  .header-home {
    background-attachment: scroll; /* Fix background attachment for smaller screens */
  }

  .header-order {
    background-attachment: scroll; /* Fix background attachment for smaller screens */
  }

  .about-content {
    flex-direction: column;
    text-align: center;
  }

  .about-left img {
    width: 150px;
    height: 150px;
  }

  .about-right {
    padding: 10px;
    text-align: center;
    margin-top: 150px;
  }

  .about-right h2 {
    text-align: center;
  }

  .card-section {
    flex-direction: column;
  }

  .card {
    background-color: var(--color-background-transparent);
    color: var(--color-white);
    border-radius: 50px;
    padding: 50px;
    box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.4); /* Optional shadow for depth */
    text-align: center;
    flex-basis: calc(33.333% - 20px); /* Adjust the width of the cards */
    max-width: 250px; /* Maximum width of the cards */
    box-sizing: border-box; /* Include padding and border in the element's width and height */
    transition: var(--transition);
  }

  .types-slide {
    flex-direction: row; /* Ensure flex direction is row for phone view */
    flex-wrap: wrap; /* Allow wrapping if necessary */
    gap:40px;
  }

  .logo-showcase img {
    height: 150px; /* Maintains the aspect ratio */
    width: 150px;
    margin-bottom: -100px;
  }
  
  .types-slide img {
    max-width: 100%;
    max-height: 300px;
  }

  .order-btn {
    width: 50%;
  }

  .logo-showcase-brand {
    font-size: 30px;
    margin-top: -130px
  }

  .logo-showcase-slogan {
    font-size: 15px;
  }

  .logo-showcase {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    min-height: 80vh; /* Optional: Change as per your design needs */
    padding-top: 0;
    filter: drop-shadow(7px 8px 1px rgba(26, 26, 26, 0.374));
    transition: var(--transition);
    
  }

  .review-slide {
    flex-direction: column;
    align-items: center;
  }

  .review-slide img {
    max-width: 70%;
    margin-bottom: 20px;
  }

  .footer-top, .footer-bottom {
    flex-direction: column;
  }
  
  .footer-column {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px; /* Adjust as needed for spacing between mobile columns */
  }

  .form-group {
    flex-direction: column;
  }

  

  .order-form input {
    width: 100%;
  }

  .product-selection {
    flex-direction: column;
    align-items: center;
  }

  .total-price {
    font-size: 1.2em;
  }

  .total-price {
    width: 70%;
  }

  .product-selection {
    display: grid; /* Ensures the image is treated as a block element */
    grid-template-columns: 1fr;
    margin: 0 auto;
  }
}



@media screen and (max-width: 305px) {

  #spline-container::after {
    width: 100%; /* Adjust to 70% of the container's width */
  }
  
}



